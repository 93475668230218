<template>
    <v-card flat>
        <v-card-text class="ma-0 pa-0">
            <v-container fluid class="pr-0 ma-0">
                <v-stepper style="box-shadow:none; padding-bottom: 0;" v-model="stepperStep">
                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <div class="d-flex align-center flex-column">
                                <span class="title font-weight-bold primary--text">What is Object Distribution?</span>
                                <span class="subtitle-2 text-center mt-5"
                                    >Object distribution allows you to selectively send files, tables, and applications across instances or spaces.</span
                                >
                                <div class="d-flex align-center mt-5">
                                    <v-btn @click="stepperStep = 2" class="secondary--text subtitle-2 mr-1" text outlined>
                                        Start
                                    </v-btn>
                                    <v-btn
                                        :disabled="loading"
                                        color="secondary"
                                        text
                                        exact
                                        :to="{
                                            name: 'snapshot-overview',
                                            params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                                        }"
                                        >Cancel</v-btn
                                    >
                                </div>
                            </div>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <div class="d-flex justify-end">
                                <span class="caption font-weight-bold">Step 1/4</span>
                            </div>
                            <div class="d-flex justify-center mb-5">
                                <span class="title font-weight-bold primary--text">What do you want to distribute?</span>
                            </div>
                            <div class="d-flex flex-column align-center">
                                <v-list-item-group disabled v-model="whatToDistribute" color="secondary">
                                    <v-row justify="center">
                                        <v-col cols="12" md="6">
                                            <v-list-item @click="() => {}">
                                                <v-list-item-content>
                                                    <div style="min-height:80px" class="d-flex flex-column align-center justify-center">
                                                        <span class="overline mt-0 mb-1 d-flex justify-center text-uppercase">option 1</span>
                                                        <span class="subtitle-2 primary--text text-uppercase text-center"
                                                            >distribute all objects in "{{ snapshotName }}"</span
                                                        >
                                                    </div>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-list-item @click="() => {}" class="pa-0 mb-10" :disabled="isStagingEmpty === true">
                                                <v-list-item-content>
                                                    <div style="min-height:80px" class="d-flex flex-column align-center justify-center">
                                                        <span class="overline mt-0 mb-1 text-uppercase">option 2</span>
                                                        <span class="subtitle-2 primary--text text-uppercase">
                                                            distribute staged objects ({{ numberOfStagedObjects }})
                                                        </span>
                                                    </div>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                    </v-row>
                                </v-list-item-group>
                                <v-alert v-if="whatToDistribute === distributionModeOptions.ALL_OBJECTS" max-width="700" type="info" text prominent>
                                    Distributing all existing objects might a require longer time to complete. For faster sharing, consider staging and
                                    distributing what is needed only.
                                </v-alert>
                            </div>
                            <div class="mb-5 d-flex justify-center" v-if="whatToDistribute === distributionModeOptions.STAGED_OBJECTS">
                                <span class="subtitle-2">Review staged objects</span>
                            </div>
                            <v-row justify="center">
                                <v-col cols="12" lg="7">
                                    <div v-if="isStagingEmpty === false && whatToDistribute === distributionModeOptions.STAGED_OBJECTS">
                                        <div v-if="stagingObjects.files.length" class="d-flex flex-column mb-5">
                                            <div class="d-flex align-center justify-space-between">
                                                <span class="font-weight-bold">Files ({{ orderedStagedObjects.files.length }})</span>
                                                <v-btn color="error" text :disabled="!stagingObjects.files.length" @click="clearStaging('files')" small>
                                                    <v-icon small>clear_all</v-icon>Unstage all files
                                                </v-btn>
                                            </div>
                                            <v-divider />
                                            <v-list class="pa-0" dense>
                                                <v-list-item-group class="pa-0 ma-0" color="primary">
                                                    <v-list-item v-for="file in orderedStagedObjects.files" :key="file.fid" class="pa-0">
                                                        <v-list-item-avatar>
                                                            <v-icon v-if="file.type === 'folder' || file.fileType === 2" small>folder</v-icon>
                                                            <v-icon v-else small>mdi-file-outline</v-icon>
                                                        </v-list-item-avatar>

                                                        <v-list-item-content>
                                                            <v-list-item-title class="primary--text subtitle-2">{{ file.filePath }}</v-list-item-title>
                                                        </v-list-item-content>

                                                        <v-list-item-action class="ma-0">
                                                            <v-btn icon @click="removeFromStaging([file], 'file')">
                                                                <v-icon small color="error">cancel</v-icon>
                                                            </v-btn>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                        </div>
                                        <div v-if="stagingObjects.tables.length" class="d-flex flex-column mb-5">
                                            <div class="d-flex align-center justify-space-between">
                                                <span class="font-weight-bold">Tables ({{ orderedStagedObjects.tables.length }})</span>
                                                <v-btn color="error" :disabled="!stagingObjects.tables.length" text @click="clearStaging('tables')" small>
                                                    <v-icon small>clear_all</v-icon>Unstage all tables
                                                </v-btn>
                                            </div>
                                            <v-divider />
                                            <v-list class="pa-0" dense>
                                                <v-list-item-group class="pa-0 ma-0" color="primary">
                                                    <v-list-item v-for="table in orderedStagedObjects.tables" :key="table.tid" class="pa-0">
                                                        <v-list-item-avatar>
                                                            <v-icon small>mdi-table-large</v-icon>
                                                        </v-list-item-avatar>

                                                        <v-list-item-content>
                                                            <v-list-item-title class="primary--text subtitle-2">{{ table.filePath }}</v-list-item-title>
                                                        </v-list-item-content>

                                                        <v-list-item-action class="ma-0">
                                                            <v-btn icon @click="removeFromStaging([table], 'table')">
                                                                <v-icon small color="error">cancel</v-icon>
                                                            </v-btn>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                        </div>
                                        <div v-if="stagingObjects.applications.length" class="d-flex flex-column">
                                            <div class="d-flex align-center justify-space-between">
                                                <span class="font-weight-bold">Applications ({{ orderedStagedObjects.applications.length }})</span>
                                                <v-btn
                                                    color="error"
                                                    :disabled="!stagingObjects.applications.length"
                                                    text
                                                    @click="clearStaging('applications')"
                                                    small
                                                >
                                                    <v-icon small>clear_all</v-icon>Unstage all applications
                                                </v-btn>
                                            </div>
                                            <v-divider />
                                            <v-alert class="mt-3" v-if="applicationsAutoAdded === true" text prominent type="success">
                                                <div class="d-flex flex-column" style="max-width:800px">
                                                    <span class="primary--text subtitle-2"
                                                        >Your recent applications have been automatically staged. This is in case you have recently installed
                                                        packages / added new applications, which will be automatically shared.</span
                                                    >
                                                    <div class="d-flex align-center">
                                                        <v-icon color="primary" small>mdi-arrow-right</v-icon>
                                                        <span class="caption font-weight-bold">Consider unstaging applications you don't want to share.</span>
                                                    </div>
                                                </div>
                                            </v-alert>
                                            <v-list class="pa-0" dense>
                                                <v-list-item-group class="pa-0 ma-0" color="primary">
                                                    <v-list-item v-for="application in stagingObjects.applications" :key="application.aid" class="pa-0">
                                                        <v-list-item-avatar>
                                                            <v-icon small>desktop_windows</v-icon>
                                                        </v-list-item-avatar>

                                                        <v-list-item-content>
                                                            <v-list-item-title class="primary--text subtitle-2">{{ application.filePath }}</v-list-item-title>
                                                        </v-list-item-content>

                                                        <v-list-item-action class="ma-0">
                                                            <v-btn icon @click="removeFromStaging([application], 'application')">
                                                                <v-icon small color="error">cancel</v-icon>
                                                            </v-btn>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                            <div class="d-flex flex-column align-center">
                                <div class="d-flex align-center mt-5">
                                    <v-btn
                                        text
                                        class="secondary--text subtitle-2 mr-1"
                                        @click="stepperStep = 3"
                                        outlined
                                        :disabled="whatToDistribute === undefined || whatToDistribute === null"
                                        >continue</v-btn
                                    >
                                    <v-btn
                                        :disabled="loading"
                                        color="secondary"
                                        text
                                        exact
                                        :to="{
                                            name: 'snapshot-overview',
                                            params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                                        }"
                                        >Cancel</v-btn
                                    >
                                </div>
                                <div class="d-flex flex-column align-center" v-if="isStagingEmpty === true">
                                    <span class="subtitle-2 primary--text my-2 text-uppercase">or</span>
                                    <div class="d-flex align-center">
                                        <v-btn
                                            text
                                            class="primary--text subtitle-2"
                                            :to="{
                                                name: 'snapshot-files',
                                                params: {
                                                    oid: $route.params.oid,
                                                    sid: $route.params.sid,
                                                    iid: $route.params.iid,
                                                    snid: $route.params.snid,
                                                    localPath: currentFileLocalPath,
                                                    fileArea: fileAreaType
                                                }
                                            }"
                                            >stage files</v-btn
                                        >
                                        <v-btn
                                            text
                                            class="primary--text subtitle-2"
                                            :to="{
                                                name: 'snapshot-applications',
                                                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                                            }"
                                            >stage applications</v-btn
                                        >
                                        <v-btn
                                            text
                                            class="primary--text subtitle-2"
                                            :to="{
                                                name: 'snapshot-tables',
                                                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                                            }"
                                            >stage tables</v-btn
                                        >
                                    </div>
                                </div>
                            </div>
                        </v-stepper-content>
                        <v-stepper-content step="3">
                            <div class="d-flex justify-space-between">
                                <v-btn @click="stepperStep = 2" color="primary" text class="caption font-weight-bold" small>
                                    <v-icon small>mdi-step-backward</v-icon>back
                                </v-btn>
                                <span class="caption font-weight-bold">Step 2/4</span>
                            </div>
                            <div class="d-flex align-center flex-column">
                                <span class="title font-weight-bold primary--text">Whom do you want to distribute to?</span>
                                <span class="subtitle-2 mt-3">Select the target organization, space, and instances that will receive your objects.</span>
                            </div>
                            <div class="d-flex flex-column align-center mt-10">
                                <div class="d-flex justify-center">
                                    <v-list-item-group v-model="targetDistribution" color="secondary">
                                        <v-row justify="center">
                                            <v-col cols="12" md="6">
                                                <v-list-item @click="fetchTargetData(distributionTargetOptions.SAME_SPACE)">
                                                    <v-list-item-content>
                                                        <div style="min-height:90px" class="d-flex flex-column align-center justify-center">
                                                            <span class="overline mb-1 text-uppercase">option 1</span>
                                                            <span class="subtitle-2 primary--text px-3 text-uppercase text-center"
                                                                >use my current organization and space</span
                                                            >
                                                        </div>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-list-item @click="fetchTargetData(distributionTargetOptions.DIFFERENT_SPACE)" class="pa-0 ma-0">
                                                    <v-list-item-content>
                                                        <div style="min-height:90px" class="d-flex flex-column align-center justify-center">
                                                            <span class="overline mb-1 text-uppercase">option 2</span>
                                                            <span class="subtitle-2 primary--text text-uppercase text-center">Another space/organization</span>
                                                        </div>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-group>
                                </div>
                                <div v-if="targetDistribution === distributionTargetOptions.DIFFERENT_SPACE" class="mt-10">
                                    <v-row class="pb-3" align="center" justify="center" no-gutters>
                                        <v-col cols="5">
                                            <span class="caption font-weight-medium mb-1">Target organization</span>
                                        </v-col>
                                        <v-col cols="7">
                                            <v-select
                                                :items="orgList"
                                                v-model="selectedOrg"
                                                @change="getOrgData(false)"
                                                label="Select Organization"
                                                single-line
                                                dense
                                                outlined
                                                hide-details
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row align="center" justify="center" no-gutters>
                                        <v-col cols="5">
                                            <span class="caption font-weight-medium mb-1">Target space</span>
                                        </v-col>
                                        <v-col cols="7">
                                            <v-select
                                                v-model="selectedSpace"
                                                :items="spaceList"
                                                @change="getSpaceData(false)"
                                                :label="orgFetching ? 'fetching spaces' : 'Select Space'"
                                                :disabled="orgFetching || !selectedOrg"
                                                outlined
                                                persistent-hint
                                                single-line
                                                hide-details
                                                dense
                                                :loading="orgFetching"
                                            >
                                                <template v-slot:item="spaceList">
                                                    <span class="caption font-weight-medium">{{ spaceList.item }} </span>
                                                </template>
                                                <template v-slot:prepend-item>
                                                    <v-text-field autofocus v-model="spaceSearch" class="px-4" label="Filter"></v-text-field>
                                                </template>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="mt-10" v-if="selectedSpace && targetDistribution !== undefined">
                                    <v-radio-group v-model="allOrSpecificInstances">
                                        <v-radio
                                            :disabled="isEditorOfDistributed === false || targetSpaceType !== spaceTypes.EDUCATION_SPACE"
                                            :label="shareWithAllLabel"
                                            value="shareWithAllInstances"
                                        ></v-radio>
                                        <v-radio
                                            :disabled="spaceInstanceList !== undefined ? !spaceInstanceList.length : true"
                                            :label="shareWithOneOrSomeLabel"
                                            value="shareWithSpecificInstances"
                                        ></v-radio>
                                    </v-radio-group>
                                </div>
                                <div v-if="showInstancesTable && targetDistribution !== undefined && fetchingSpaceData === false">
                                    <v-data-table
                                        v-model="selectedInstances"
                                        show-select
                                        :headers="headers"
                                        :items="instanceFetching ? [] : spaceInstanceList"
                                        :single-select="isDevelopment === true"
                                        :items-per-page="-1"
                                        :search="instanceSearch"
                                        item-key="iid"
                                        :loading="fetchingSpaceData"
                                    >
                                        <template v-slot:top>
                                            <v-alert
                                                v-if="isEditorOfDistributed === true && targetSpaceType === spaceTypes.EDUCATION_SPACE"
                                                text
                                                type="warning"
                                            >
                                                <div class="d-flex flex-column" style="max-width:800px">
                                                    <span class="font-weight-bold">Limited distribution</span>
                                                    <span>
                                                        With this option, only the selected student
                                                        <span v-if="currentSpaceType === spaceTypes.EDUCATION_SPACE && isDevelopment">instance</span>
                                                        <span v-else-if="currentSpaceType === spaceTypes.EDUCATION_SPACE && !isDevelopment">instances</span>
                                                        will receive your objects. If you want all student instances to receive the same contents, select
                                                        <span class="font-weight-bold">{{ shareWithAllLabel }}</span>
                                                    </span>
                                                </div>
                                            </v-alert>
                                            <v-text-field
                                                v-model="instanceSearch"
                                                append-icon="mdi-magnify"
                                                label="Search"
                                                single-line
                                                hide-details
                                            ></v-text-field>
                                        </template>
                                        <template width="100" v-slot:item.long_id="{ item }">{{ item.long_id }}</template>
                                        <template v-slot:item.description="{ item }">{{ item.description }}</template>
                                    </v-data-table>
                                </div>
                                <div
                                    v-if="
                                        selectedSpace &&
                                            fetchingSpaceData === false &&
                                            !spaceInstanceList.length &&
                                            isEditorOfDistributed === true &&
                                            targetSpaceType === spaceTypes.EDUCATION_SPACE
                                    "
                                    no-gutters
                                    class="d-flex justify-center"
                                >
                                    <v-alert prominent outlined type="success">
                                        <div class="d-flex flex-column" style="max-width:800px">
                                            <span class="subtitle-1 ma-0 font-weight-bold">Distributing before inviting</span>
                                            <span class="ma-0">
                                                Because you haven't invited any students yet, shared material will be available to new students after you have
                                                invited them.
                                            </span>
                                        </div>
                                    </v-alert>
                                </div>
                                <div
                                    v-if="
                                        (selectedSpace || currentOrgSpaceAreTargets) &&
                                            fetchingSpaceData === false &&
                                            !spaceInstanceList.length &&
                                            (isEditorOfDistributed === false || targetSpaceType === spaceTypes.RESEARCH_SPACE)
                                    "
                                    no-gutters
                                    class="d-flex justify-center"
                                >
                                    <v-alert prominent text type="warning" max-width="600">
                                        <p class="subtitle-1 ma-0 font-weight-bold">No targets available</p>
                                        <ul>
                                            <li v-if="isEditorOfDistributed === false">
                                                You have insufficient privileges to distribute to everyone.
                                            </li>
                                            <li v-if="!spaceInstanceList.length">
                                                There are no instances you are an editor of in this
                                                <span v-if="currentSpaceType === spaceTypes.EDUCATION_SPACE">course</span>
                                                <span v-else-if="currentSpaceType === spaceTypes.RESEARCH_SPACE">research project</span
                                                ><span v-else>space</span>.
                                            </li>
                                        </ul>
                                    </v-alert>
                                </div>
                            </div>
                            <div class="mt-5 d-flex justify-center align-center">
                                <v-btn
                                    @click="setSelectedSpaceTableStatus"
                                    class="secondary--text subtitle-2 mr-1"
                                    text
                                    outlined
                                    :loading="orgFetching === true || fetchingSpaceData === true"
                                    :disabled="disableContinueInstanceSelection"
                                    >continue</v-btn
                                >
                                <v-btn
                                    :disabled="loading"
                                    color="secondary"
                                    text
                                    exact
                                    :to="{
                                        name: 'snapshot-overview',
                                        params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                                    }"
                                    >Cancel</v-btn
                                >
                            </div>
                        </v-stepper-content>
                        <v-stepper-content step="4">
                            <div class="d-flex justify-space-between">
                                <v-btn @click="stepperStep = 3" color="primary" text class="caption font-weight-bold" small>
                                    <v-icon small>mdi-step-backward</v-icon>back
                                </v-btn>
                                <span class="caption font-weight-bold">Step 3/4</span>
                            </div>
                            <div class="d-flex align-center flex-column mb-5">
                                <span class="title font-weight-bold primary--text">How do you want to share your objects?</span>
                            </div>
                            <v-list-item-group class="mt-10" v-model="distributionStrategy" color="secondary">
                                <v-row justify="space-between" no-gutters>
                                    <v-col cols="12" md="4">
                                        <v-list-item :value="availableDistributionStrategyOptions[0].backendCode" :disabled="customDistribution === true">
                                            <v-list-item-content>
                                                <div class="d-flex flex-column align-center justify-center">
                                                    <div>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-icon color="primary" v-on="on" class="mr-1" small>mdi-information-outline</v-icon>
                                                            </template>
                                                            <span
                                                                >Distribute all your objects, and if a similar object exists in the target instance then it will
                                                                be overwritten.</span
                                                            > </v-tooltip
                                                        ><span class="text-uppercase">overwrite</span>
                                                    </div>
                                                    <img width="290" height="150" src="@/assets/overwrite.svg" />
                                                </div>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-list-item :value="availableDistributionStrategyOptions[1].backendCode" :disabled="customDistribution === true">
                                            <v-list-item-content>
                                                <div class="d-flex flex-column align-center justify-center">
                                                    <div>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-icon color="primary" v-on="on" class="mr-1" small>mdi-information-outline</v-icon>
                                                            </template>
                                                            <span
                                                                >Among the objects you are distributing, only those that do not exist in the target instances
                                                                will be shared.</span
                                                            > </v-tooltip
                                                        ><span class="text-uppercase">distribute extra</span>
                                                    </div>
                                                    <img width="290" height="150" src="@/assets/distribute_extra.svg" />
                                                </div>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-list-item
                                            :value="availableDistributionStrategyOptions[2].backendCode"
                                            class="pa-0 ma-0"
                                            :disabled="customDistribution === true"
                                        >
                                            <v-list-item-content>
                                                <div class="d-flex flex-column align-center justify-center">
                                                    <div>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-icon color="primary" v-on="on" class="mr-1" small>mdi-information-outline</v-icon>
                                                            </template>
                                                            <span
                                                                >Erease all objects in the target instance, and replace them with the objects that you are
                                                                sharing.</span
                                                            > </v-tooltip
                                                        ><span class="text-uppercase">clear target and replace</span>
                                                    </div>
                                                    <img width="290" height="150" src="@/assets/clear_target.svg" />
                                                </div>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                </v-row>
                            </v-list-item-group>
                            <v-alert v-if="snapshotTargetBeforeDistributing === true" type="success" outlined prominent class="mt-5">
                                <div class="d-flex flex-column" style="max-width:800px">
                                    <span class="ma-0 font-weight-bold">Backup Snapshot</span>
                                    <span
                                        >A snapshot of the current state of each target instance will be taken. This will allow users to recover or restore
                                        overwritten files.</span
                                    >
                                </div>
                            </v-alert>
                            <v-alert
                                v-else-if="snapshotTargetBeforeDistributing === false && targetSpaceTablesAreEnabled"
                                type="warning"
                                text
                                prominent
                                class="mt-5"
                            >
                                <div class="d-flex flex-column" style="max-width:800px">
                                    <span class="ma-0 font-weight-bold">Backup Snapshot</span>
                                    <p>
                                        Since tables are enabled in the target space, target instance(s) will <span class="font-weight-bold">not</span> be
                                        snapshotted before distribution for increased performance. This is recommended only for distributing completely new
                                        material to avoid unrecoverable data loss in the target(s). You can change this setting in the
                                        <span class="font-weight-bold">Custom Distribution</span> section, however distribution will be significantly slower if
                                        snapshotting before distribution is enabled.
                                    </p>
                                </div>
                            </v-alert>
                            <v-alert
                                v-else-if="snapshotTargetBeforeDistributing === false && !targetSpaceTablesAreEnabled"
                                type="warning"
                                text
                                prominent
                                class="mt-5"
                            >
                                <div class="d-flex flex-column" style="max-width:800px">
                                    <span class="ma-0 font-weight-bold">Backup Snapshot</span>
                                    <p>
                                        Target instance(s) will <span class="font-weight-bold">not</span> be snapshotted before distribution. You can change
                                        this setting in the <span class="font-weight-bold">Custom Distribution</span> section.
                                    </p>
                                </div>
                            </v-alert>
                            <div class="ml-5">
                                <div>
                                    <v-switch v-model="customDistribution">
                                        <template v-slot:label>
                                            <span :class="customDistribution ? 'subtitle-1 font-weight-bold primary--text' : 'subtitle-1'"
                                                >Custom Distribution</span
                                            >
                                        </template>
                                    </v-switch>
                                </div>
                                <div v-if="customDistribution">
                                    <div xs3>
                                        <v-checkbox
                                            v-model="snapshotTargetBeforeDistributing"
                                            label="Snapshot target before distributing"
                                            class="font-weight-medium"
                                        ></v-checkbox>
                                        <v-divider color="grey" style="max-width:500px" class="mt-3" />
                                    </div>
                                    <div v-if="whatToDistribute === distributionModeOptions.ALL_OBJECTS || selectedObjectTypes.includes('workspace')" xs3>
                                        <v-checkbox
                                            :disabled="
                                                whatToDistribute === distributionModeOptions.STAGED_OBJECTS &&
                                                    workspaceCheck &&
                                                    selectedObjectTypes.includes('workspace')
                                            "
                                            v-model="workspaceCheck"
                                            label="Workspace objects"
                                            class="font-weight-medium"
                                        ></v-checkbox>
                                        <v-select
                                            :disabled="!workspaceCheck"
                                            v-model="workspaceDistMode"
                                            :items="availableDistributionStrategyOptions"
                                            class="fileSelect"
                                            persistent-hint
                                            item-text="text"
                                            item-value="backendCode"
                                            single-line
                                            style="width:500px"
                                        ></v-select>
                                    </div>
                                    <div v-if="whatToDistribute === distributionModeOptions.ALL_OBJECTS || selectedObjectTypes.includes('personal')" xs3>
                                        <v-checkbox
                                            :disabled="
                                                whatToDistribute === distributionModeOptions.STAGED_OBJECTS &&
                                                    personalCheck &&
                                                    selectedObjectTypes.includes('personal')
                                            "
                                            v-model="personalCheck"
                                            label="Personal files"
                                            class="font-weight-medium"
                                        ></v-checkbox>
                                        <v-select
                                            :disabled="!personalCheck"
                                            v-model="personalFilesDistMode"
                                            :items="availableDistributionStrategyOptions"
                                            item-text="text"
                                            item-value="backendCode"
                                            class="fileSelect"
                                            style="width:500px"
                                        ></v-select>
                                    </div>
                                    <div v-if="whatToDistribute === distributionModeOptions.ALL_OBJECTS || selectedObjectTypes.includes('table')" xs3>
                                        <v-checkbox
                                            :disabled="
                                                whatToDistribute === distributionModeOptions.STAGED_OBJECTS &&
                                                    tableCheck &&
                                                    selectedObjectTypes.includes('table')
                                            "
                                            v-model="tableCheck"
                                            label="Tables"
                                            class="font-weight-medium"
                                        ></v-checkbox>
                                        <v-select
                                            :disabled="!tableCheck"
                                            v-model="tablesDistMode"
                                            :items="availableDistributionStrategyOptions"
                                            item-text="text"
                                            item-value="backendCode"
                                            class="fileSelect"
                                            style="width:500px"
                                        ></v-select>
                                    </div>
                                </div>
                                <div class="mt-5" justify="center" align="center">
                                    <v-btn
                                        @click="stepperStep = 5"
                                        class="secondary--text subtitle-2 mr-1"
                                        text
                                        outlined
                                        :disabled="customDistribution === false && distributionStrategy === undefined"
                                    >
                                        continue
                                    </v-btn>
                                    <v-btn
                                        :disabled="loading"
                                        color="secondary"
                                        text
                                        exact
                                        :to="{
                                            name: 'snapshot-overview',
                                            params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                                        }"
                                        >Cancel</v-btn
                                    >
                                </div>
                            </div>
                        </v-stepper-content>
                        <v-stepper-content step="5">
                            <div class="d-flex justify-space-between">
                                <v-btn @click="stepperStep = 4" color="primary" text class="caption font-weight-bold" small>
                                    <v-icon small>mdi-step-backward</v-icon>back
                                </v-btn>
                                <span class="caption font-weight-bold">Step 4/4</span>
                            </div>
                            <div class="d-flex align-center flex-column mb-5">
                                <span class="title font-weight-bold primary--text">Notify target users about your distribution with a message</span>
                            </div>
                            <v-checkbox v-model="notifyTargetUsers" label="Notify Target Users"></v-checkbox>
                            <v-textarea v-if="notifyTargetUsers" v-model="notificationText" rows="2" auto-grow>
                                <template v-slot:label>Your message</template>
                            </v-textarea>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-container>
        </v-card-text>
        <v-card-actions v-if="stepperStep === 5">
            <div class="d-flex justify-center align-center" style="width:100%">
                <v-btn
                    class="secondary--text subtitle-2 ml-1"
                    :loading="loading"
                    :disabled="notifyTargetUsers && !notificationText.length"
                    @click="distribute"
                    outlined
                    text
                    >share objects</v-btn
                >
                <v-btn
                    :disabled="loading"
                    color="secondary"
                    text
                    exact
                    :to="{
                        name: 'snapshot-overview',
                        params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                    }"
                    >Cancel</v-btn
                >
                <v-alert :value="error" type="error" icon="warning" class="mt-3" outlined>{{ errorContent }}</v-alert>
                <v-alert :value="inputError" type="error" icon="warning" class="mt-3" outlined
                    >Please select the instances you want to share the current snapshot with</v-alert
                >
            </div>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { enumsData } from '@/mixins/enums'
import regionsMixin from '@/mixins/regions'
import { sortArray } from '@/utils'
const distTargetType = {
    SHARE_WITH_ALL_INSTANCES: 'shareWithAllInstances',
    SHARE_WITH_SPECIFIC_INSTANCES: 'shareWithSpecificInstances'
}
const distributionStrategyOptions = {
    OVERWRITE: { text: 'Distribute all objects (overwrite target)', backendCode: 'OVERWRITE' },
    IGNORE_EXISTING: { text: 'Distribute extra objects compared to target', backendCode: 'IGNORE_EXISTING' },
    MIRROR: { text: 'Clean and replace target', backendCode: 'MIRROR' },
    SKIP: { text: 'Skip', backendCode: 'SKIP' }
}
export default {
    mixins: [enumsData, regionsMixin],
    data() {
        return {
            selectedOrg: '',
            selectedSpace: '',
            currentStep: 1,
            selectedInstances: [],
            spaceSnapshotData: [],
            spaceInstanceList: [],
            targetDistributedInstance: [],
            spaces: [],
            stepperStep: 1,
            orgFetching: false,
            instanceFetching: false,
            error: false,
            spaceSearch: '',
            customDistribution: false,
            errorContent: 'An Error has occurred',
            inputError: false,
            instanceSearch: '',
            headers: [
                {
                    text: 'Instance Name',
                    align: 'left',
                    value: 'long_id',
                    width: '20%'
                },
                { text: 'Description', align: 'left', value: 'description' }
            ],
            loading: false,
            workspaceFilesOnly: true,
            shareWithAll: true,
            whatToDistribute: null,
            allOrSpecificInstances: '',
            selectedShare: '',
            workspaceCheck: false,
            personalCheck: false,
            tableCheck: false,
            currentOrgSpaceAreTargets: false,
            applicationsAutoAdded: false,
            fetchingSpaceData: false,
            snapshotTargetBeforeDistributing: false,
            targetSpaceTablesAreEnabled: false,
            notifyTargetUsers: true,
            notificationText: '',
            distributionStrategy: distributionStrategyOptions.OVERWRITE.backendCode,
            workspaceDistMode: distributionStrategyOptions.OVERWRITE.backendCode,
            personalFilesDistMode: distributionStrategyOptions.OVERWRITE.backendCode,
            tablesDistMode: distributionStrategyOptions.OVERWRITE.backendCode,
            targetDistribution: 0,
            distributionTargetOptions: {
                SAME_SPACE: 0,
                DIFFERENT_SPACE: 1
            },
            distributionModeOptions: {
                ALL_OBJECTS: 0,
                STAGED_OBJECTS: 1
            }
        }
    },
    computed: {
        ...mapState('snapshotStore', ['filesToDistribute', 'stagingObjects', 'applications', 'fileAreaType']),
        ...mapGetters('spaceStore', ['currentSpaceType']),
        ...mapState('spaceStore', ['spaceInstances']),
        ...mapState(['userOrgs']),
        ...mapGetters('snapshotStore', ['isStagingEmpty', 'currentFileLocalPath', 'isDevelopment']),
        ...mapState('orgStore', ['spacesWithPrimarySnapshots']),
        ...mapState('appStore', ['recentApps']),
        orgList() {
            const orgs = this.userOrgs.map(org => org.long_id)
            return orgs
        },
        spaceList() {
            const eduAndResSpaces = this.$data.spaces.filter(space => space.sid.toString() !== this.$route.params.sid.toString())
            const sortedSpaceList = sortArray(eduAndResSpaces, 'long_id', 'ascending', false)
            if (this.$data.spaceSearch) {
                const spacesListFilteredBySearch = sortedSpaceList.filter(space => space.long_id.toLowerCase().includes(this.$data.spaceSearch.toLowerCase()))
                return spacesListFilteredBySearch.map(space => space.long_id)
            } else {
                return sortedSpaceList.map(space => space.long_id)
            }
        },
        availableDistributionStrategyOptions() {
            if (this.currentSpaceType === this.spaceTypes.EDUCATION_SPACE && this.whatToDistribute === this.distributionModeOptions.ALL_OBJECTS) {
                return Object.values(distributionStrategyOptions)
            }
            return Object.values(distributionStrategyOptions).filter(item => item.backendCode !== distributionStrategyOptions.SKIP.backendCode)
        },
        currentSpaceData() {
            if (this.spacesWithPrimarySnapshots) {
                const spaceData = this.spacesWithPrimarySnapshots.find(space => space.sid.toString() === this.$route.params.sid.toString())
                return spaceData
            }
            return {}
        },
        shareWithAllLabel() {
            if (this.targetSpaceType === this.spaceTypes.EDUCATION_SPACE) {
                return 'Share with all students in this class'
            }
            return 'Share with all instances'
        },
        shareWithOneOrSomeLabel() {
            if (this.targetSpaceType === this.spaceTypes.EDUCATION_SPACE && this.isDevelopment) {
                return 'Share with one student in this class'
            } else if (this.targetSpaceType === this.spaceTypes.EDUCATION_SPACE && !this.isDevelopment) {
                return 'Share with some students in this class'
            } else if (this.isDevelopment) {
                return 'Share with one instance'
            }
            return 'Share with specific instances'
        },
        targetSpaceType() {
            if (this.selectedSpace && this.targetDistribution === this.distributionTargetOptions.DIFFERENT_SPACE) {
                const space = this.spaces.find(space => space.long_id === this.selectedSpace)
                return space ? space.space_type : null
            } else if (this.targetDistribution === this.distributionTargetOptions.SAME_SPACE) {
                return this.currentSpaceType
            }
            return null
        },
        selectedObjectTypes() {
            const objects = this.stagingObjects.files.concat(this.stagingObjects.tables).concat(this.stagingObjects.applications)
            const objectTypes = objects.map(object => object.fileType)
            return objectTypes
        },
        currentOrg() {
            return this.$store.getters['orgStore/orgLongName']
        },
        disableContinueInstanceSelection() {
            if (
                this.targetDistribution === this.distributionTargetOptions.SAME_SPACE &&
                this.allOrSpecificInstances === distTargetType.SHARE_WITH_ALL_INSTANCES &&
                this.isEditorOfDistributed === true &&
                this.$data.targetDistributedInstance !== undefined &&
                this.$data.targetDistributedInstance.length
            ) {
                return false
            } else if (
                this.targetDistribution === this.distributionTargetOptions.SAME_SPACE &&
                this.allOrSpecificInstances === distTargetType.SHARE_WITH_SPECIFIC_INSTANCES &&
                this.spaceInstanceList !== undefined &&
                this.spaceInstanceList.length > 0 &&
                this.selectedInstances.length > 0
            ) {
                return false
            } else if (
                this.targetDistribution === this.distributionTargetOptions.DIFFERENT_SPACE &&
                this.selectedSpace &&
                this.allOrSpecificInstances === distTargetType.SHARE_WITH_ALL_INSTANCES &&
                this.isEditorOfDistributed === true &&
                this.$data.targetDistributedInstance !== undefined &&
                this.$data.targetDistributedInstance.length
            ) {
                return false
            } else if (
                this.targetDistribution === this.distributionTargetOptions.DIFFERENT_SPACE &&
                this.selectedSpace &&
                this.allOrSpecificInstances === distTargetType.SHARE_WITH_SPECIFIC_INSTANCES &&
                this.spaceInstanceList.length > 0 &&
                this.selectedInstances.length > 0
            ) {
                return false
            }
            return true
        },
        showInstancesTable() {
            if (
                this.spaceInstanceList !== undefined &&
                this.allOrSpecificInstances === distTargetType.SHARE_WITH_SPECIFIC_INSTANCES &&
                this.spaceInstanceList.length &&
                this.selectedSpace
            ) {
                return true
            }
            return false
        },
        currentSpace() {
            return this.$store.getters['spaceStore/spaceLongNameById'](this.$route.params.sid)
        },
        currentInstance() {
            return this.$store.getters['instanceStore/instanceLongNameById'](this.$route.params.iid)
        },
        snapshotName() {
            const snapshot = this.$store.getters['snapshotStore/snapshotLongNameById'](this.$route.params.snid)
            if (snapshot) {
                return snapshot.toUpperCase()
            }
            return ''
        },
        numberOfStagedObjects() {
            return this.stagingObjects.files.length + this.stagingObjects.tables.length + this.stagingObjects.applications.length
        },
        selectedOrgAndSpace() {
            return { org: this.$data.selectedOrg, space: this.$data.selectedSpace }
        },
        orderedStagedObjects() {
            const folders = this.stagingObjects.files.filter(file => file.type === 'folder')
            const nonFolders = this.stagingObjects.files.filter(file => file.type !== 'folder')
            const filesAndFolders = folders.concat(nonFolders)
            return {
                files: filesAndFolders,
                tables: this.stagingObjects.tables,
                applications: this.stagingObjects.applications
            }
        },
        isEditorOfDistributed() {
            if (this.targetDistribution === this.distributionTargetOptions.SAME_SPACE) {
                const distInstance = this.spaceInstances.filter(instance => this.checkDistributedInstance(instance.long_id) === true)
                if (distInstance.length > 0) {
                    if (this.checkEditorRole(distInstance[0].role) === true) {
                        return true
                    }
                }
            } else if (
                this.targetDistribution === this.distributionTargetOptions.DIFFERENT_SPACE &&
                this.$data.targetDistributedInstance !== undefined &&
                this.$data.targetDistributedInstance.length &&
                this.checkEditorRole(this.$data.targetDistributedInstance[0].role) === true
            ) {
                return true
            }
            return false
        }
    },
    methods: {
        setSelectedSpaceTableStatus() {
            if (this.$data.currentOrgSpaceAreTargets === true && this.currentSpaceData && this.currentSpaceData.has_tables) {
                this.$data.targetSpaceTablesAreEnabled = true
                this.$data.snapshotTargetBeforeDistributing = false
            } else if (this.$data.currentOrgSpaceAreTargets === true && this.currentSpaceData && !this.currentSpaceData.has_tables) {
                this.$data.targetSpaceTablesAreEnabled = false
                this.$data.snapshotTargetBeforeDistributing = true
            } else if (this.$data.currentOrgSpaceAreTargets === false) {
                const targetSpaceTableData = this.$data.spaces.filter(space => space.long_id === this.selectedSpace)[0]
                this.$data.snapshotTargetBeforeDistributing = !targetSpaceTableData.has_tables
                this.$data.targetSpaceTablesAreEnabled = !!targetSpaceTableData.has_tables
            } else {
                this.$data.snapshotTargetBeforeDistributing = true
                this.$data.targetSpaceTablesAreEnabled = false
            }
            this.$data.stepperStep = 4
        },
        fetchTargetData: function(target) {
            this.$data.selectedInstances = []
            if (target === this.distributionTargetOptions.DIFFERENT_SPACE) {
                this.getOrgData(false)
            } else if (target === this.distributionTargetOptions.SAME_SPACE) {
                if (this.currentSpaceType !== this.spaceTypes.RESEARCH_SPACE) {
                    this.$data.allOrSpecificInstances = distTargetType.SHARE_WITH_ALL_INSTANCES
                }
                this.$data.spaces = []
                this.$data.spaceInstanceList = this.getSpaceData(true)
                this.$data.selectedOrg = this.currentOrg
                this.$data.selectedSpace = this.currentSpace
            }
        },
        addToStaging: function(data) {
            if (data.length > 0) {
                var filesWithPaths = []
                var fileOrder = data.length
                data.forEach(file => {
                    var fileObject = file
                    fileObject.filePath = file.long_id
                    fileObject.fileType = 'application'
                    filesWithPaths.push(fileObject)
                    fileOrder = fileOrder - 1
                    if (fileOrder === 0) {
                        this.$store.dispatch('snapshotStore/updateStagingObjects', {
                            itemType: 'application',
                            newItems: filesWithPaths,
                            updateMode: 'add'
                        })
                    }
                })
            }
        },
        getOrgData: function(currentOrgSelected) {
            this.$data.spaceInstanceList = []
            this.$data.selectedSpace = ''
            var oid
            var success = false
            if (currentOrgSelected) {
                this.$data.selectedOrg = this.currentOrg
                oid = parseInt(this.$route.params.oid, 10)
                success = true
            } else if (this.selectedOrg.length) {
                oid = this.userOrgs.filter(org => org.long_id === this.selectedOrg)[0].oid
                success = true
            }
            if (success === true) {
                this.$data.orgFetching = true
                this.$axios.get(`/orgs/${oid}/spaces`).then(response => {
                    this.$data.spaces = response.data
                    this.$data.orgFetching = false
                    if (currentOrgSelected) {
                        this.$data.selectedSpace = this.currentSpace
                    }
                    this.getSpaceData(true)
                })
            }
        },
        clearStaging: function(itemType) {
            this.$store.dispatch('snapshotStore/clearStagingObjects', itemType)
        },
        removeFromStaging: function(fileData, objectType) {
            this.$store.dispatch('snapshotStore/updateStagingObjects', {
                itemType: objectType,
                newItems: fileData,
                updateMode: 'remove'
            })
        },
        getSpaceData: function(currentSpaceSelected) {
            // check if space is in same region
            let sameRegion = true
            if (!currentSpaceSelected) {
                const currentRegion = this.$data.spaces.filter(space => space.long_id === this.selectedSpace)[0].region
                sameRegion = currentRegion === this.getCurrentRegionEnum()
            }
            if (sameRegion) {
                this.$data.fetchingSpaceData = true
                this.$data.selectedInstances = []
                var sid
                if (currentSpaceSelected) {
                    sid = parseInt(this.$route.params.sid, 10)
                } else {
                    sid = this.$data.spaces.find(space => space.long_id === this.selectedSpace).sid
                }
                this.$axios
                    .post(`spaces/${sid}/get_instances`, { skip_empty_viewer_instances: true })
                    .then(response => {
                        this.$data.targetDistributedInstance = response.data.filter(instance => this.checkDistributedInstance(instance.long_id) === true)
                        this.$data.spaceInstanceList = response.data.filter(
                            instance =>
                                this.checkDistributedInstance(instance.long_id) === false &&
                                this.checkEditorRole(instance.role) === true &&
                                (!this.isDevelopment || this.$route.params.iid.toString() !== instance.iid.toString())
                        )
                    })
                    .finally(() => {
                        this.$data.fetchingSpaceData = false
                    })
            } else {
                this.$data.spaceInstanceList = []
                this.$store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Space in different region',
                    dialogText:
                        'Target space is in a different region - please reach out to support@nuvolos.cloud for assistance on distributing across regions.',
                    dialogAction: 'dismiss'
                })
            }
        },
        distribute: function() {
            const userTasksRoute = this.$router.resolve({ name: 'user-tasks' }).href
            this.$data.loading = true
            this.$data.error = false
            this.$data.inputError = false
            var instanceIds
            var apiURL
            if (this.$data.allOrSpecificInstances === distTargetType.SHARE_WITH_ALL_INSTANCES) {
                instanceIds = [parseInt(this.$data.targetDistributedInstance[0].iid, 10)]
            } else {
                instanceIds = this.$data.selectedInstances.map(instance => parseInt(instance.iid, 10))
            }
            var putBody = {
                method: 'RSYNC',
                target_iids: instanceIds,
                auto_snapshot: this.$data.snapshotTargetBeforeDistributing,
                lib_mode: distributionStrategyOptions.MIRROR.backendCode
            }
            if (this.$data.customDistribution) {
                if (this.$data.workspaceCheck && (this.isStagingEmpty === true || this.selectedObjectTypes.includes('workspace'))) {
                    putBody.files_mode = this.workspaceDistMode
                }
                if (this.$data.personalCheck && (this.isStagingEmpty === true || this.selectedObjectTypes.includes('personal'))) {
                    putBody.home_mode = this.personalFilesDistMode
                }
                if (this.$data.tableCheck && (this.isStagingEmpty === true || this.selectedObjectTypes.includes('table'))) {
                    putBody.tables_mode = this.tablesDistMode
                }
            } else {
                putBody.home_mode =
                    this.currentSpaceType === this.spaceTypes.EDUCATION_SPACE && this.whatToDistribute === this.distributionModeOptions.ALL_OBJECTS
                        ? distributionStrategyOptions.SKIP.backendCode
                        : this.distributionStrategy
                putBody.files_mode = putBody.tables_mode = this.distributionStrategy
            }
            if (this.$data.notifyTargetUsers) {
                putBody.notify_target_users = true
                if (this.$data.notificationText) {
                    const textLines = this.$data.notificationText.split('\n').map(function(text, index, array) {
                        if (index < array.length - 1) {
                            return text + '<br>'
                        } else {
                            return text
                        }
                    })
                    const htmlText = textLines.join('\n')
                    putBody.custom_email_message = htmlText
                }
            }
            if (this.whatToDistribute === this.distributionModeOptions.ALL_OBJECTS) {
                apiURL = '/snapshots/' + this.$route.params.snid + '/distribute_async'
            } else if (this.whatToDistribute === this.distributionModeOptions.STAGED_OBJECTS) {
                apiURL = '/snapshots/' + this.$route.params.snid + '/distribute_selected_async'
                if (this.stagingObjects.files.length) {
                    const fids = this.stagingObjects.files.map(file => file.fid)
                    putBody.source_fids = fids
                }
                if (this.stagingObjects.applications.length) {
                    const aoids = this.stagingObjects.applications.map(application => parseInt(application.aoid, 10))
                    putBody.source_aoids = aoids
                }
                if (this.stagingObjects.tables.length) {
                    const tids = this.stagingObjects.tables.map(table => parseInt(table.tid, 10))
                    putBody.source_tids = tids
                }
            }
            this.$axios
                .put(apiURL, putBody)
                .then(response => {
                    var snackbarMessage
                    if (this.currentSpaceData && this.currentSpaceData.has_tables && this.currentSpaceData.has_tables === true) {
                        snackbarMessage = `Object distribution has been started. Because tables are activated, this operation might take several minutes. You will receive a summary email of the outcome. You can track progress <a href="${userTasksRoute}">here</a>`
                    } else {
                        snackbarMessage = `Object distribution has been started. You will receive a summary email of the outcome. You can track progress <a href="${userTasksRoute}">here</a>`
                    }
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: snackbarMessage,
                        snackBarTimeout: 10000,
                        snackBarIcon: 'info'
                    })
                    this.$store.dispatch('userStore/fetchUserTasks')
                })
                .catch(() => {
                    this.$data.error = true
                })
                .finally(() => {
                    this.clearStaging('all')
                    this.$router.push({
                        name: 'snapshot-files',
                        params: {
                            oid: this.$route.params.oid,
                            sid: this.$route.params.sid,
                            iid: this.$route.params.iid,
                            snid: this.$route.params.snid,
                            localPath: this.currentFileLocalPath,
                            fileArea: this.fileAreaType
                        }
                    })
                    this.$data.loading = false
                })
        }
    },
    mounted() {
        if ([this.spaceTypes.RESEARCH_SPACE, this.spaceTypes.EDUCATION_SPACE].includes(this.currentSpaceType)) {
            this.$data.targetDistribution = this.distributionTargetOptions.SAME_SPACE
            this.getOrgData(true)
        } else {
            this.$data.targetDistribution = this.distributionTargetOptions.DIFFERENT_SPACE
        }
        if (this.isStagingEmpty === false) {
            this.$data.whatToDistribute = this.distributionModeOptions.STAGED_OBJECTS
            this.$data.stepperStep = 2
        } else {
            this.$data.whatToDistribute = this.distributionModeOptions.ALL_OBJECTS
        }
        if (this.currentSpaceType === this.spaceTypes.EDUCATION_SPACE && !this.isStagingEmpty && !this.stagingObjects.applications.length) {
            const availableApps = this.recentApps.filter(app => app.instance_id.toString() === this.$route.params.iid.toString())
            if (availableApps.length > 0) {
                this.addToStaging(availableApps)
                this.$data.applicationsAutoAdded = true
            }
        } else if (this.currentSpaceType === this.spaceTypes.RESEARCH_SPACE || this.isEditorOfDistributed === false) {
            this.$data.allOrSpecificInstances = distTargetType.SHARE_WITH_SPECIFIC_INSTANCES
        }
    },
    watch: {
        whatToDistribute: function(nextVal, preValue) {
            if (nextVal === this.distributionModeOptions.ALL_OBJECTS) {
                this.$data.workspaceCheck = true
                this.$data.personalCheck = false
                this.$data.tableCheck = true
            } else if (nextVal === this.distributionModeOptions.STAGED_OBJECTS) {
                this.$data.workspaceCheck = this.selectedObjectTypes.includes('workspace')
                this.$data.personalCheck = this.selectedObjectTypes.includes('personal')
                this.$data.tableCheck = this.selectedObjectTypes.includes('table')
                this.$data.workspaceDistMode = distributionStrategyOptions.OVERWRITE.backendCode
                this.$data.personalFilesDistMode =
                    this.currentSpaceType === this.spaceTypes.EDUCATION_SPACE
                        ? distributionStrategyOptions.SKIP.backendCode
                        : distributionStrategyOptions.OVERWRITE.backendCode
                this.$data.tablesDistMode = distributionStrategyOptions.OVERWRITE.backendCode
            }
        },
        targetSpaceType: function(nextVal) {
            if (nextVal && nextVal !== this.spaceTypes.EDUCATION_SPACE) {
                this.$data.allOrSpecificInstances = distTargetType.SHARE_WITH_SPECIFIC_INSTANCES
            }
        },
        customDistribution: function(nextVal) {
            if (nextVal === true) {
                this.$data.distributionStrategy = null
            } else if (nextVal === false) {
                this.$data.distributionStrategy = this.availableDistributionStrategyOptions[0].backendCode
                this.setSelectedSpaceTableStatus()
            }
        },
        isStagingEmpty: function(nextVal) {
            if (nextVal === true && this.whatToDistribute === this.distributionModeOptions.STAGED_OBJECTS) {
                this.$data.whatToDistribute = this.distributionModeOptions.ALL_OBJECTS
            }
        },
        selectedOrgAndSpace: function(nextVal) {
            if (nextVal.org === this.currentOrg && nextVal.space === this.currentSpace) {
                this.$data.currentOrgSpaceAreTargets = true
            } else {
                this.$data.currentOrgSpaceAreTargets = false
            }
        },
        spaceInstanceList: function(nextVal) {
            if (
                nextVal !== undefined &&
                nextVal.length === 0 &&
                this.isEditorOfDistributed === true &&
                this.targetSpaceType &&
                this.targetSpaceType !== this.spaceTypes.RESEARCH_SPACE
            ) {
                this.$data.allOrSpecificInstances = distTargetType.SHARE_WITH_ALL_INSTANCES
            }
        },
        isEditorOfDistributed: function(nextVal) {
            if (nextVal === false) {
                this.$data.allOrSpecificInstances = distTargetType.SHARE_WITH_SPECIFIC_INSTANCES
            } else if (nextVal === true && this.targetSpaceType !== this.spaceTypes.RESEARCH_SPACE) {
                this.$data.allOrSpecificInstances = distTargetType.SHARE_WITH_ALL_INSTANCES
            }
        }
    },
    beforeDestroy() {
        this.$store.dispatch('snapshotStore/setFilesToDistribute', [])
    }
}
</script>
